"use client";
import { combineReducers } from "@reduxjs/toolkit";
import authSlice from "./features/auth/authSlice";
import { apiSlice } from "./api/apiSlice";

const reducers = combineReducers({
	[apiSlice.reducerPath]: apiSlice.reducer,
	UserAuth: authSlice.reducer,
});

export default reducers;
