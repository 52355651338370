"use client";
import Loading from "@/app/loading";
import { Spinner } from "@/components/icons";
import { useAppSelector } from "@/store";
import { useLoadUserQuery } from "@/store/api/apiSlice";
import { UserAssessmentStatusEnum } from "@/utils/enums-constant";
import { redirect, usePathname } from "next/navigation";
import React, { ReactNode, useEffect, useState } from "react";
import ISComingSoon from "@/components/is_coming_soon";

const ProtectRoutes = ({ children }: { children: ReactNode }) => {
	const { isLoading } = useLoadUserQuery({});
	const { user, loading, error } = useAppSelector(
		(state: any) => state.UserAuth,
	);
	const [isComingSoon, setIsComingSoon] = useState(false);
	const pathName = usePathname();
	const [mounted, setMounted] = useState(false);

	useEffect(() => {
		if (window.innerWidth > 750) setIsComingSoon(true);
		else {
			if (
				Object.keys(user).length &&
				!loading &&
				(!error || error === "")
			) {
				if (!user.phoneVerified && !pathName.includes("/onboarding")) {
					redirect("/onboarding");
				}
				if (user.phoneVerified && !pathName.includes("/dashboard")) {
					redirect("/dashboard");
				}
			}
			if (
				(!Object.keys(user).length || (error && error !== "")) &&
				!loading &&
				!isLoading &&
				(pathName.includes("/dashboard") ||
					pathName.includes("/onboarding"))
			) {
				redirect("/phone-login");
			}
			setMounted(true);
			setIsComingSoon(false);
		}
	}, [pathName, user, loading, error, isLoading]);

	return (
		<>
			{isComingSoon ? (
				<ISComingSoon />
			) : !mounted || isLoading || loading ? (
				<>
					<main className="flex h-[100dvh]  w-screen flex-col">
						<section className="container mx-auto flex h-full w-full items-center justify-center text-secondary-800">
							<Spinner className="h-12 w-12 text-secondary-600" />
						</section>
					</main>
				</>
			) : (
				<>{children}</>
			)}
		</>
	);
};

export const CheckClientIntake = ({ children }: { children: ReactNode }) => {
	// States and Hooks
	const [loading, setLoading] = useState(true);
	const { user, loading: userLoading } = useAppSelector(
		(state: any) => state.UserAuth,
	);
	const pathname = usePathname();

	// Check for User assignment
	useEffect(() => {
		if (
			pathname != "/dashboard/client-intake" &&
			user.assessment_status === UserAssessmentStatusEnum.INCOMPLETE
		) {
			redirect("/dashboard/client-intake");
		}
		setLoading(false);
	}, [user, pathname]);
	return <>{!loading && !userLoading ? children : <Loading />}</>;
};

export default ProtectRoutes;
